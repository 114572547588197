import * as React from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

// markup
const IndexPage = () => {
  return (
    <main>
      <section class="advice">
        <div>
          <h1 class="advice__title vibecourt-font">
            <span class="orange">V</span>IBE<span class="purple">C</span>OURT
          </h1>
          <p class="paragraph-text">
            Making some big changes, we'll be right back<span class="green">...</span>
          </p>
        </div>
      </section>
      <section class="city-stuff">
        <ul class="skyscrappers__list">
          <li class="skyscrapper__item skyscrapper-1"></li>
          <li class="skyscrapper__item skyscrapper-2"></li>
          <li class="skyscrapper__item skyscrapper-3"></li>
          <li class="skyscrapper__item skyscrapper-4"></li>
          <li class="skyscrapper__item skyscrapper-5"></li>
        </ul>
        <ul class="tree__container">
          <li class="tree__list">
            <ul class="tree__item tree-1">
              <li class="tree__trunk"></li>
              <li class="tree__leaves"></li>
            </ul>
            <ul class="tree__item tree-2">
              <li class="tree__trunk"></li>
              <li class="tree__leaves"></li>
            </ul>
            <ul class="tree__item tree-3">
              <li class="tree__trunk"></li>
              <li class="tree__leaves"></li>
            </ul>  
            <ul class="tree__item tree-4">
              <li class="tree__trunk"></li>
              <li class="tree__leaves"></li>
            </ul>  
            <ul class="tree__item tree-5">
              <li class="tree__trunk"></li>
              <li class="tree__leaves"></li>
            </ul>  
            <ul class="tree__item tree-6">
              <li class="tree__trunk"></li>
              <li class="tree__leaves"></li>
            </ul>  
            <ul class="tree__item tree-7">
              <li class="tree__trunk"></li>
              <li class="tree__leaves"></li>
            </ul>  
            <ul class="tree__item tree-8">
              <li class="tree__trunk"></li>
              <li class="tree__leaves"></li>
            </ul>  
          </li>
        </ul>
        <ul class="crane__list crane-1">
          <li class="crane__item crane-cable crane-cable-1"></li>
          <li class="crane__item crane-cable crane-cable-2"></li>
          <li class="crane__item crane-cable crane-cable-3"></li>
          <li class="crane__item crane-stand"></li>
          <li class="crane__item crane-weight"></li>
          <li class="crane__item crane-cabin"></li>
          <li class="crane__item crane-arm"></li>
        </ul>
        <ul class="crane__list crane-2">
          <li class="crane__item crane-cable crane-cable-1"></li>
          <li class="crane__item crane-cable crane-cable-2"></li>
          <li class="crane__item crane-cable crane-cable-3"></li>
          <li class="crane__item crane-stand"></li>
          <li class="crane__item crane-weight"></li>
          <li class="crane__item crane-cabin"></li>
          <li class="crane__item crane-arm"></li>
        </ul>
        <ul class="crane__list crane-3">
          <li class="crane__item crane-cable crane-cable-1"></li>
          <li class="crane__item crane-cable crane-cable-2"></li>
          <li class="crane__item crane-cable crane-cable-3"></li>
          <li class="crane__item crane-stand"></li>
          <li class="crane__item crane-weight"></li>
          <li class="crane__item crane-cabin"></li>
          <li class="crane__item crane-arm"></li>
        </ul>
      </section>
    </main>
  )
}

export default IndexPage
